@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .box {
    @apply py-2 px-4 sm:px-8 md:py-4 md:px-8 lg:max-w-5xl xl:max-w-6xl lg:mx-auto;
  }

  .h1 {
    @apply text-3xl md:text-4xl lg:text-5xl;
  }

  .main-bg {
    @apply bg-blue-50;
  }

  .btn {
    @apply flex gap-2 items-center bg-purple-500 text-white disabled:bg-gray-400 disabled:text-gray-600 px-2 py-1 rounded-sm hover:scale-110 transition-all disabled:hover:scale-100 disabled:cursor-default;
  }

  .input,
  .select {
    @apply w-full transition-all outline-none px-2 py-1 sm:px-3 sm:py-2 bg-white border-gray-300 focus-within:ring-1 focus-within:rounded-sm ring-purple-700 border-b-2 focus-within:border-white;
  }

  .navbar-user-li {
    @apply pl-3 pr-6 py-1.5 hover:bg-purple-600 hover:text-white transition-all flex items-center gap-3;
  }

  .navbar-user-li:lang(kh) {
    @apply text-sm;
  }

  .cv-grid-container {
    @apply border-t border-gray-300 pt-6 pb-10 grid grid-cols-1 gap-y-6 gap-x-10 lg:grid-cols-2;
  }

  .cv-input-label {
    @apply mb-2 block text-sm text-gray-600;
  }

  .cv-input {
    @apply outline-none transition-all px-2 py-[6px] border bg-white border-gray-300 rounded-sm w-full focus-within:ring-2 focus-within:ring-purple-400 text-gray-700;
  }

  .display-cv-header {
    @apply text-purple-600 font-semibold text-2xl mb-4;
  }

  .display-cv-grid {
    @apply border-t border-gray-300 text-sm xs:text-base py-4 grid gap-y-4 gap-x-10 grid-cols-1 md:grid-cols-2;
  }

  .cv-text {
    @apply font-medium;
    font-family: "Poppins", Arial, Helvetica, sans-serif !important;
  }

  select.cv-input {
    @apply px-[1px];
  }

  /* .select {
    @apply transition-all outline-none px-3 py-2 border-gray-300 focus-within:ring-1 focus-within:rounded-sm ring-purple-700 border-b-2;
  } */
  .nav-item-container {
    @apply bg-indigo-50 lg:bg-gray-50 gap-8 items-center flex flex-col absolute top-12 md:top-16 py-6 lg:py-0 w-screen left-0 overflow-y-hidden transition-all duration-500
    lg:static lg:max-h-screen lg:flex-row lg:w-auto lg:top-0 lg:overflow-visible;
  }
  .hero-img-slider-container {
    @apply transition-all duration-500 lg:divide-x divide-solid divide-black p-4 sm:p-8 md:p-8 flex flex-col lg:flex-row gap-8 lg:gap-4 items-center;
  }
}

/* @font-face {
  font-family: jjjkkk;
  src: url("https://fonts.googleapis.com/css2?family=Battambang:wght@100;300;400;700;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
  unicode-range: U+1780;
} */

:lang(kh) {
  font-family: "Battambang";
}

:lang(eng),
input:lang(kh),
select:lang(kh),
option:lang(kh),
textarea:lang(kh) {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}

input:lang(kh)::placeholder {
  font-family: "Battambang";
}

.modal-container {
  width: 100vw;
  height: calc(100vh - 48px);
}

@media (min-width: 768px) {
  .modal-container {
    height: calc(100vh - 64px);
  }
}
